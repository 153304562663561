/* Base */

html
  font-family: $basic
  font-weight: 300
  font-size: 1rem
  font-smooth: always
  -webkit-font-smoothing: antialised
body
  padding: 0
  color: $lite
  font-size: 95%
  font-family: $basic
  font-weight: 300
  background: $white
  background-size: 100%
  max-width: 100%
  overflow-x: hidden
  &.fix-mob
    +v-sm
      overflow: hidden
  a
    text-decoration: none
    cursor: pointer !important
  input,
  button
    border: none
    outline: none

.site-content
  display: flex
  flex-flow: column nowrap
  justify-content: space-between

.content
  display: block
  width: 100%
  height: auto
  margin: 0
  padding: 0

.container
  display: block
  width: 96%
  max-width: 1200px
  margin-left: auto
  margin-right: auto
  &.text-center
    text-align: center
  +v-xl
    max-width: 1440px
  +v-lg
    max-width: 1200px
  +v-md
    max-width: 1024px
  +v-sm
    max-width: 768px
  +v-xs
    max-width: 100%

#demo_block,
.h-160
  height: 160vh
