// Header

#top_block
  position: fixed
  top: 0
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  padding: 0 2%
  height: 80px
  z-index: 2000
.hb-burger
  display: none
  +v-sm
    display: block
  span
    position: fixed
    right: 28px
    width: 30px
    height: 2px
    background-color: $premium
    transition: all ease-out .2s
    &.one
      top: 29px
      transform: rotate(0)
    &.two
      top: 40px
      right: 32px
    &.three
      top: 51px
      transform: rotate(0)
  &.opened
    span
      right: 26px
      &.one
        top: 40px
        transform: rotate(-45deg)
      &.two
        width: 0
      &.three
        top: 40px
        transform: rotate(45deg)

.hb-header
  display: flex
  flex-flow: row wrap
  justify-content: space-between
  align-items: center
  width: 100%
  height: 80px
.hb-logo
  margin-top: -24px
  &__link
    display: flex
    justify-content: flex-start
    align-items: center
    height: 80px
  +v-sm
    position: fixed
    top: 10px
    left: 15px
  &__img
    width: auto
    height: 100px
    object-fit: cover
  &__text
    display: inline-block
    margin-left: 10px
    padding: 0
    color: $white
    font-family: $basic
    font-size: 1.3em
    font-weight: 500
    transform: translateY(15px)
    line-height: 1
    span
      display: inline-block
      color: $white
      font-size: .6em
      font-weight: 700
      transform: translateY(-5px)
      line-height: 1
