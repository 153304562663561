// Footer

.footer
  position: relative
  background: $dark
  z-index: 1
  *
    color: $white !important
  +v-sm
    padding-top: 25px
  .f-bottom
    padding-top: 25px
  .partners
    width: 100%
    padding: 0 10%
    display: flex
    flex-flow: row wrap
    justify-content: space-around
    align-items: center
    height: 200px
    +v-sm
      height: 100px
      width: 80%
      margin: 0 auto
    &__link
      margin: 0 16px
      img
        width: auto
        height: 40px
        transition: all ease-out .3s
      &:hover
        img
          opacity: 0.75
.menu
  &--footer
    display: flex
    flex-flow: row wrap
    justify-content: center
    align-items: center
    width: 100%
    +v-sm
      flex-flow: column nowrap
      margin: 40px auto 32px
    &__text,
    &__link
      margin: 0 20px 16px
      color: $white
      font-size: 1em
      transition: all ease-out .3s
      &:hover
        color: $grey-lite
      &.active
        &:hover
          color: $lite-blue
    &__text
      color: $white
      margin-top: 2px
      font-size: 0.975em
      font-weight: 300
      &:hover
        color: $grey-lite
