/* Variables */
@import url('https://fonts.googleapis.com/css2?family=Merienda+One&family=Mate+SC&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
// Colors
$premium: #14C7FF
$dark: #0E1D2E
$white: #ffffff
$medium: $premium
$lite: #6c6c6c
$blue: $premium
$blue-lite: $premium
$grey-lite: #787878
$lite-blue: $premium
$bg-lite: #F7F8FC
$intro: $premium
$theme: $premium
$button: $premium
$logo: $premium
$marine: $premium

$shadow-4: 0 4px 4px rgba(0,0,0,.15)
$shadow-10: 0 2px 10px rgba(0,0,0,.02)

// Fonts
$fira: 'Fira Sans', sans-serif
$play: 'Playfair Display', sans-serif

$basic: $fira
$serif: $play

// animation
.au
  animation: Up 1.4s .4s
.ad
  animation: Down 1.4s .4s
.al
  animation: Left 1.7s .4s
.ar
  animation: Right 1.7s .4s

@keyframes Right
  from
    transform: translateX(60px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

@keyframes Left
  from
    transform: translateX(-60px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

@keyframes Up
  from
    transform: translateY(20px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

@keyframes Down
  from
    transform: translateY(-20px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

// Placeholder
=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
