// Features
#feature_block
  position: relative
  width: 100%
  height: auto
  min-height: 100vh
  z-index: 1
  .container
    position: relative
    z-index: 2
  +v-sm
    min-height: auto
