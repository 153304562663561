.btn
  display: inline-flex
  justify-content: center
  align-items: center
  width: auto
  height: 46px
  padding: 1px 32px 0
  color: $white
  font-size: 1em
  font-weight: 600
  text-transform: uppercase
  border-radius: 2px
  transition: all ease-out .3s
  background-color: $medium
  cursor: pointer !important
  +v-sm
    zoom: .85
  &:hover
    background-color: $theme !important
    cursor: pointer !important
  &--premium
    color: $white
    background-color: darken($premium, 10%)
  &--medium
    color: $white
    background: $medium
  &--big
    padding: 0 60px
    height: 60px
    font-size: 1em
