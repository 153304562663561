// Intro

.intro
  width: 100%
  overflow: hidden
  background: #666 url('../../assets/img/bgs/intro001.jpg') center center no-repeat
  background-blend-mode: multiply
  background-attachment: fixed
  background-size: cover
  +v-xs
    padding-top: 20vh
  &__title
    width: 70%
    margin-left: auto
    margin-right: auto
    color: $white
    font-size: 3.5em
    font-family: $basic
    font-weight: 700
    line-height: 1.25
    letter-spacing: .015em
    text-align: center
    animation: Up 1.4s 1s
    +v-md
      font-size: 4em
    +v-sm
      margin-top: 20px
      font-size: 2.5em
      line-height: 1.05
    +v-xs
      width: 80%
      margin: 20px auto
      font-size: 1.95em
      line-height: 1.25
  &__subtitle
    width: 100%
    padding: 0
    color: $premium
    font-size: 1.35em
    font-weight: 600
    animation: Down 1.4s .4s
    +v-sm
      font-size: 1.2em
      line-height: 1.25
    +v-xs
      width: 80%
      margin: 20px auto
      line-height: 1.5
  &__text
    width: 100%
    margin: 20px auto
    padding: 0
    color: $medium
    font-size: 1.15em
    font-weight: 500
    line-height: 1.5
    +v-sm
      width: 76%
      font-size: .9em
      margin: 10px auto

@keyframes Up
  from
    transform: translateY(20px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

@keyframes Down
  from
    transform: translateY(-20px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1
